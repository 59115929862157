var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-0 ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-12 pa-0"},[_c('ConfirmationModal',{ref:"activeToggleConfirmationModal",attrs:{"title":_vm.confirmationTitle,"subtitle":"","message":_vm.confirmationMessage,"confirmColor":"error darken-1"}}),_c('DataTableLayout',{attrs:{"options":_vm.layoutOptions},on:{"search":function($event){_vm.search = $event},"add":_vm.openForm},scopedSlots:_vm._u([{key:"actions",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"loading":false,"items":_vm.institutions,"search":_vm.search,"loading-text":"Searching... Please wait"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NoData'),_c('h2',{staticClass:"mb-12 subtitle-1"},[_vm._v("No data found")])]},proxy:true},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.enableOrDisableInstitution(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('address',{attrs:{"href":("tel: " + (item.contactNumber))}},[_vm._v(" "+_vm._s(((item.street) + ", " + (item.city) + ", " + (item.countrySubEntity) + ", " + (item.country)))+" ")])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.contactPerson))]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":("tel: " + (item.contactNumber))}},[_vm._v(_vm._s(item.contactNumber))]),_c('br'),_c('a',{attrs:{"href":("mailto: " + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('DataTableActions',{attrs:{"actions":_vm.actions,"data":{ index: index, item: item }}})]}}],null,true)})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }