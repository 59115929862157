<template>
  <v-row justify="center" class="px-0 ma-0">
    <v-col class="col-12 pa-0">
      <!-- Modals -->
      <ConfirmationModal
        ref="activeToggleConfirmationModal"
        :title="confirmationTitle"
        subtitle=""
        :message="confirmationMessage"
        confirmColor="error darken-1"
      />

      <DataTableLayout
        @search="search = $event"
        :options="layoutOptions"
        @add="openForm"
      >
        <!-- Use to add addition actions such as buttons-->
        <template #actions> </template>

        <!-- content to display -->
        <template #content>
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :loading="false"
            :items="institutions"
            :search="search"
            loading-text="Searching... Please wait"
          >
            <template v-slot:no-data>
              <NoData />
              <h2 class="mb-12 subtitle-1">No data found</h2>
            </template>

            <template v-slot:[`item.isActive`]="{ item }">
              <v-checkbox
                v-model="item.isActive"
                @click="enableOrDisableInstitution(item)"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <address :href="`tel: ${item.contactNumber}`">
                {{
                  `${item.street}, ${item.city}, ${item.countrySubEntity}, ${item.country}`
                }}
              </address>
            </template>
            <template v-slot:[`item.contact`]="{ item }">
              <span class="mr-2">{{ item.contactPerson }}</span> <br />
              <a :href="`tel: ${item.contactNumber}`">{{
                item.contactNumber
              }}</a>
              <br />
              <a :href="`mailto: ${item.email}`">{{ item.email }}</a>
            </template>
            <template v-slot:[`item.actions`]="{ index, item }">
              <DataTableActions :actions="actions" :data="{ index, item }" />
            </template>
          </v-data-table>
        </template>
      </DataTableLayout>
    </v-col>
  </v-row>
</template>

<style scoped>
</style>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
export default {
  mixins: [PageMixin],
  data: () => ({
    institution: {},

    confirmationTitle: '',
    confirmationMessage: '',

    layoutOptions: {
      hideSearch: false, // removes search button totally from view
      showSearch: false, // toggle search bar
      buttonText: 'Add Institution',
      title: 'Institution Management',
      subtitle: 'Lists institutions belonging to the organization.'
    },
    search: '',
    actions: [],

    headers: [
      {
        text: 'Enabled',
        align: 'start',
        filterable: false,
        value: 'isActive'
      },
      {
        text: 'Tax Number',
        value: 'taxIdentificationNumber',
        filterable: true
      },
      { text: 'Name', value: 'name', filterable: true },
      { text: 'Contact Details', value: 'contact' },
      { text: 'Address', value: 'address', filterable: true },
      { text: 'Actions', align: 'end', value: 'actions', sortable: false }
    ]
  }),

  async mounted() {
    this.actions = [
      {
        icon: 'mdi-pencil-outline',
        action: 'Edit',
        actionCallBack: (index, item) => {
          this.openForm(item);
        }
      },
      {
        icon: 'mdi-pail-remove-outline',
        action: 'Delete',
        actionCallBack: (index, item) => {
          this.removeInstitution(item);
        }
      }
    ];

    try {
      await this.fetchInstitutions();
    } catch (error) {
      this.httpErrorHandler(error);
    }
  },

  computed: {
    ...mapGetters('coreModule', ['provinces', 'countries', 'addressTypes']),
    ...mapGetters('institutionModule', ['institutions'])
  },

  methods: {
    ...mapActions('institutionModule', [
      'updateInstitution',
      'fetchInstitutions',
      'deleteInstitution'
    ]),
    openForm(item) {
      const route = item
        ? `/settings/institution/${item._id}`
        : '/settings/institution';

      this.$router.push(route);
    },

    enableOrDisableInstitution(item) {
      this.confirmationTitle =
        'Are you sure you want to deactive this institution?';
      this.confirmationMessage =
        'This action may lead to persons unable to log into the system';
      if (item.isActive) {
        this.confirmationTitle =
          'Are you sure you want to active this institution?';
        this.confirmationMessage =
          'This action may allow unintended persons to log into the system';
      }

      this.$refs.activeToggleConfirmationModal.show(
        async () => {
          //This executes if user selects yes
          try {
            this.institution = { ...item };
            await this.updateInstitution({
              id: this.institution._id,
              payload: this.institution
            });

            this.showSnack({
              color: 'info',
              title: `Update`,
              message: `Institution ${item.name} was ${
                item.isActive ? 'Enabled' : 'Disabled'
              }.`
            });
          } catch {
            item.isActive = !item.isActive;
          }
        },
        () => {
          //This executes if user selects no
          item.isActive = !item.isActive;
        }
      );
    },

    removeInstitution(item) {
      this.confirmationTitle =
        'Are you sure you want to delete this institution?';
      this.confirmationMessage =
        'This action will prevent users from accessing the applications';

      this.$refs.activeToggleConfirmationModal.show(async () => {
        try {
          await this.deleteInstitution(item._id);
          this.showSnack({
            color: 'info',
            title: `Removed`,
            message: `Institution ${item.name} was successfully removed.`
          });
        } catch (error) {
          this.httpErrorHandler(error);
        }
      });
    }
  }
};
</script>